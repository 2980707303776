import React from "react";

import { formatDate, formatMoney } from "../../../../helpers/format";
import { downloadResponseFile } from "../../../../helpers/responses";

import { useModal } from "../../../../hooks/contexts";
import { useCustomFetch, useLoading } from "../../../../hooks/async";

import { Button, Input, Select } from "../../../../components/Form";
import {
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "../../../../components/Data/Table";
import { Paginate } from "../../../../components/Paginate/Paginate";

import { Circle } from "../../../../components/Loading";
import { DownloadSimple } from "phosphor-react";
import styles from "./RequestsList.module.css";

export function RequestsList({
  requestList,
  searchTotalRequestRecordsAndRequests,
  searchManagers,
  searchCustomers,
  searchManagersDebounced,
  searchCustomersDebounced,
  filteredManager,
  filterCustomer,
  managerOptions,
  customerOptions,
  filteredSolicitation,
  searchRequests,
  pagination,
}) {
  const customFetch = useCustomFetch();
  const Modal = useModal();

  const generatingRequestProof = useLoading();

  const handleClickGenerateRequestProof = React.useCallback(
    async (requestId) => {
      const url = `/premiations/report/generateRequestReceipt/${requestId}`;

      try {
        generatingRequestProof.setLoading(true);
        const response = await customFetch(url, {
          method: "GET",
        });
        if (response.status === 200) {
          downloadResponseFile({
            file: response.object,
            type: "application/pdf",
            fileName: `comprovante_da_solicitacao_com_id_${requestId}.pdf`,
          });
        } else if (response.status === 500) {
          Modal.error(response.message, response.object);
        }
      } catch (error) {
        Modal.error(error);
      } finally {
        generatingRequestProof.setLoading(false);
      }
    },
    [Modal, customFetch, generatingRequestProof]
  );

  React.useEffect(() => {
    if (!requestList.value.length) searchTotalRequestRecordsAndRequests();
    if (!managerOptions.value.length) searchManagers();
    if (!customerOptions.value.length) searchCustomers();
  }, []); // eslint-disable-line

  return (
    <div className={`container ${styles.container}`}>
      <div className={styles.filtersContainer}>
        <div>
          <label htmlFor="filteredSolicitation" className="label">
            ID Solicitação
          </label>
          <Input
            id="filteredSolicitation"
            placeholder="Digite o id da solicitação"
            value={filteredSolicitation.value}
            onChange={filteredSolicitation.onChange}
          />
        </div>
        <div>
          <label htmlFor="filteredManager" className="label">
            Gerente
          </label>
          <Select
            id="filteredManager"
            placeholder="Selecione um gerente para filtrar"
            value={filteredManager.value}
            onChange={(value) => {
              filteredManager.setValue(value);
            }}
            onInputChange={(value) => searchManagersDebounced(value)}
            options={managerOptions.value}
            isLoading={managerOptions.isSearching}
          />
        </div>
        <div>
          <label htmlFor="filterCustomer" className="label">
            Cliente
          </label>
          <Select
            id="filterCustomer"
            placeholder="Selecione um cliente para filtrar"
            value={filterCustomer.value}
            onChange={(value) => {
              filterCustomer.setValue(value);
            }}
            onInputChange={(value) => searchCustomersDebounced(value)}
            options={customerOptions.value}
            isLoading={customerOptions.isSearching}
          />
        </div>
        <Button
          className={styles.filtersContainer__btnSearchSolicitation}
          onClick={() => {
            searchTotalRequestRecordsAndRequests();
          }}
        >
          Buscar Solicitações
        </Button>
      </div>
      <span className="separator" />
      <div className={styles.dataContainer}>
        {requestList.value.length &&
        !requestList.isSearching &&
        !generatingRequestProof.isLoading ? (
          <div className={styles.requestsTableContainer}>
            {requestList.value.map((clientItem, index) => {
              return (
                <div className={styles.table} key={index}>
                  <Table title={clientItem.nomeCliente} className={styles.requestsTable}>
                    <THead>
                      <Tr>
                        <Th>ID Solicitação</Th>
                        <Th>Gerente Solicitante</Th>
                        <Th>Total Cliente</Th>
                        <Th>Total Solicitação</Th>
                        <Th>Data</Th>
                        <Th></Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {clientItem.premiacoes.map((request, index) => {
                        return (
                          <Tr key={index}>
                            <Td heading="ID Solicitação" width="10rem">
                              {request.solicitacoes.idSolicitacao}
                            </Td>
                            <Td heading="Gerente Solicitante">{request.usuario.nome}</Td>
                            <Td heading="Total Cliente" width="180px">
                              {formatMoney(request.solicitacoes.totalCliente)}
                            </Td>
                            <Td heading="Total Solicitação" width="180px">
                              {formatMoney(request.solicitacoes.total)}
                            </Td>
                            <Td heading="Data" width="11.25rem">
                              {formatDate(request.solicitacoes.dataInsercao, "dd/MM/yy às hh:mm:ss")}
                            </Td>
                            <Td heading="Ver Solicitação" width="6.25rem" data-option>
                              <Button
                                type="button"
                                variant="edit"
                                className={styles.seeRequestButton}
                                onClick={() => {
                                  handleClickGenerateRequestProof(request.solicitacoes.idSolicitacao);
                                }}
                                data-option-button
                              >
                                <DownloadSimple weight="fill" />
                              </Button>
                            </Td>
                          </Tr>
                        );
                      })}
                    </TBody>
                  </Table>
                </div>
              );
            })}
          </div>
        ) : requestList.isSearching ? (
          <div
            className={`loadingContainer ${styles.loadingRequestListContainer}`}
          >
            <Circle size={100} />
          </div>
        ) : generatingRequestProof.isLoading ? (
          <div
            className={`loadingContainer ${styles.loadingRequestListContainer}`}
          >
            <Circle size={100} />
            <span className="loadingMessage">Gerando Comprovante</span>
          </div>
        ) : (
          <p className={`lineCardMessage`}>Nenhuma solicitação encontrada</p>
        )}
        {!generatingRequestProof.isLoading ? (
          <Paginate
            classNameContainer={styles.paginationContainer}
            totalRecords={pagination.totalRecords}
            maxItems={pagination.maxItems}
            currentPage={pagination.currentPage}
            setCurrentPage={pagination.setCurrentPage}
            onPageChange={(page) => searchRequests(page)}
          />
        ) : null}
      </div>
    </div>
  );
}
